<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-text>
          <div>
            <v-sheet
              tile
              height="54"
              color="grey lighten-3"
              class="d-flex"
            >
              <v-btn
                icon
                class="ma-2"
                @click="$refs.timetable.prev()"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-spacer />
              <v-card-title>
                {{ $t('general.timetable') }}
                <v-toolbar-title v-if="$refs.timetable" class="ml-3">
                  {{ $refs.timetable.title }}
                </v-toolbar-title>
              </v-card-title>
              <v-spacer />
              <v-btn
                icon
                class="ma-2"
                @click="$refs.timetable.next()"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-sheet>
            <v-sheet height="600">
              <v-progress-linear
                v-if="!Lb_IsLoaded"
                class="my-1"
                indeterminate
                color="primary"
              />
              <v-calendar
                ref="timetable"
                v-model="start"
                :disabled="!Lb_IsLoaded"
                :type="type"
                :start="start"
                :end="end"
                :min-weeks="minWeeks"
                :max-days="maxDays"
                :now="now"
                :dark="dark"
                :weekdays="weekdays"
                :first-interval="intervals.first"
                :interval-minutes="intervals.minutes"
                :interval-count="intervals.count"
                :interval-height="intervals.height"
                :interval-style="intervalStyle"
                :show-interval-label="showIntervalLabel"
                :short-intervals="shortIntervals"
                :short-months="shortMonths"
                :short-weekdays="shortWeekdays"
                :color="color"
                :events="events"
                :event-overlap-mode="mode"
                :event-overlap-threshold="45"
                :event-color="getEventColor"
                locale="CH"
                @click:event="showEvent"
              >
                <template v-slot:event="{ event }">
                  <div class="ml-1">
                    {{event.description}} {{ event.room }}
                  </div>
                </template>
              </v-calendar>
              <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                :activator="selectedElement"
                offset-x
              >
                <v-card
                  color="grey lighten-4"
                  min-width="350px"
                  flat
                >
                  <v-toolbar
                    :color="selectedEvent.color"
                    dark
                  >
                    <v-toolbar-title v-html="selectedEvent.description" />
                    <v-spacer />
                    <v-btn
                      icon
                      @click="selectedOpen = false"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    {{ $t('general.room') }}: <span v-html="selectedEvent.room" /><br>
                    {{ $t('Start') }}: <span v-html="selectedEvent.timeStart" /><br>
                    {{ $t('End') }}: <span v-html="selectedEvent.timeEnd" /><br>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-sheet>
          </div>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
  const weekdaysDefault = [1, 2, 3, 4, 5, 6, 0]

  const intervalsDefault = {
    first: 0,
    minutes: 60,
    count: 24,
    height: 48,
  }

  const stylings = {
    default (interval) {
      return undefined
    },
    workday (interval) {
      const inactive = interval.weekday === 0 ||
        interval.weekday === 6 ||
        interval.hour < 9 ||
        interval.hour >= 17
      const startOfHour = interval.minute === 0
      const dark = this.dark
      const mid = dark ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'

      return {
        backgroundColor: inactive ? (dark ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,0.05)') : undefined,
        borderTop: startOfHour ? undefined : '1px dashed ' + mid,
      }
    },
    past (interval) {
      return {
        backgroundColor: interval.past ? (this.dark ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,0.05)') : undefined,
      }
    },
  }

  import { get } from '../../../worker/worker-api'
  import { mapGetters } from 'vuex'
  import { config } from '../../../data/config'
  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Stundenplan',
    },
    data () {
      return {
        start: new Date().toISOString().substr(0, 10),
        end: new Date().toISOString().substr(0, 10),
        Lb_IsLoaded:true,
        minWeeks: 1,
        maxDays: 7,
        now: null,
        dark: false,
        weekdays: weekdaysDefault,
        intervals: intervalsDefault,
        styleInterval: 'default',
        shortIntervals: true,
        shortMonths: false,
        shortWeekdays: false,
        color: 'primary',
        type: 'week',
        types: ['month', 'week', 'day', '4day'],
        mode: 'stack',
        modes: ['stack', 'column'],
        weekday: [0, 1, 2, 3, 4, 5, 6],
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        value: '',
        events: [],
        colors: [
          'blue',
          'indigo',
          'deep-purple',
          'cyan',
          'green',
          'orange',
          'grey darken-1',
        ],
        i_TacherAddressRoleID: null,
      }
    },
    watch: {
      i_PlanningPeriodID () {
        const Ls_Start = this.getPlanningPeriod.startDate.toISOString().slice(0, 10)
        const Ls_End = this.getPlanningPeriod.endDate.slice(0, 10)
        this.getTimetable(Ls_Start, Ls_End)
      }
    },
    computed: {
      ...mapGetters(['getPlanningPeriodID', 'getPlanningPeriod', 'getImpersonation']),
      i_PlanningPeriodID () {
        return this.getPlanningPeriodID
      },
      intervalStyle () {
        return stylings[this.styleInterval].bind(this)
      },
      minEndDate() {
        const Lt_Start = new Date(this.start);
        const Lt_End = new Date(this.end);
        if(Lt_End < Lt_Start) {
          this.end = Lt_Start.toISOString().substr(0, 10);
          return Lt_Start.toISOString().substr(0, 10);
        }
        return this.nowDate;
      }
    },
    beforeMount () {
      if (this.getImpersonation.b_Status && this.getImpersonation.s_Role === config.roles[1]) this.i_TacherAddressRoleID = this.getImpersonation.i_AddressRoleID
      else this.i_TacherAddressRoleID = this.$smt.getAddressRoleID(this.$route.meta.role)
      const Ls_Start = this.getPlanningPeriod.startDate.toISOString().slice(0, 10)
      const Ls_End = this.getPlanningPeriod.endDate.slice(0, 10)
      this.getTimetable(Ls_Start, Ls_End)
    },
    methods: {
      getTimetable(start, end) {
        const events = [];
        this.Lb_IsLoaded = false
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/${this.i_TacherAddressRoleID}/period/${this.getPlanningPeriodID}/timetable`, {
          s_Start: start,
          s_End: end,
        })
          .then(response => { 
            if (response.status === 401) {
              //this.signOut()
              //this.$smt.logout()
              return
            }
            this.Lb_IsLoaded = true;
            let Ls_Color = this.colorEvent;
            if (response.length > 0) this.start = response[0].t_Start.slice(0, 10)
            for (let i = 0; i < response.length; i++) {
              const Lt_Start = new Date(response[i].t_Start);
              const Lt_End = new Date(response[i].t_End);
              const Ls_TimeStart = Lt_Start.getHours() + ':' + String(Lt_Start.getMinutes()).padStart(2, "0")
              const Ls_TimeEnd = Lt_End.getHours() + ':' + String(Lt_End.getMinutes()).padStart(2, "0")
              let first = Lt_Start.getFullYear() + '-' + (Lt_Start.getMonth() + 1) + '-' + Lt_Start.getDate(); 
              if(Lt_Start.getHours() > 0) {
                first += ' ' + Lt_Start.getHours() + ':' + Lt_Start.getMinutes();
              }  
              let second = Lt_End.getFullYear() + '-' + (Lt_End.getMonth() + 1) + '-' + Lt_End.getDate(); 
              if(Lt_End.getHours() > 0) {
                second += ' ' + Lt_End.getHours() + ':' + Lt_End.getMinutes();
              }
              if(response[i].s_Type === 'lesson') Ls_Color = 'primary';
              if(response[i].s_Type === 'event') Ls_Color = 'orange';
              events.push({
                description: response[i].s_Title + (response[i].s_Subject !== null ? ' - ' + response[i].s_Subject : ''),
                name: response[i].s_Title,
                subject: response[i].s_Subject,
                start: first,
                end: second,
                timed: 1,
                color: Ls_Color,
                room: response[i].s_Room,
                timeStart: Ls_TimeStart,
                timeEnd: Ls_TimeEnd,
              });
            }
            this.events = events;
            this.$refs.timetable.scrollToTime('07:00');
          })
          .catch(error => {
            this.Lb_IsLoaded = true;
            console.log('Worker error: ', error)
          })
      },
      getEventColor (event) {
        return event.color
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      showIntervalLabel (interval) {
        return interval.minute === 0
      },
    }
  }
</script>
